var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-left mb-5"},[_c('b-card',{attrs:{"no-body":""}},[_c('b-tabs',{attrs:{"pills":"","card":""}},[_c('b-tab',{attrs:{"title":"Ürün Listesi","active":""}},[_c('b-card-text',[_c('div',{staticClass:"row"},[_c('SearchNavbar'),_c('b-col',{attrs:{"md":"8"}},[_c('OrderProduct')],1)],1),(_vm.getLoading)?_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"d-flex justify-content-center flex-column align-items-center mb-3"},[_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"label":"Large Spinner","type":"grow"}}),_c('span',{staticClass:"ml-2 d-block text-center mt-3"},[_vm._v("Loading...")])],1)]):_vm._e(),(!_vm.getLoading)?_c('ProductList'):_vm._e(),(!_vm.getLoading)?_c('div',{staticClass:"row"},[(_vm.allProducts.length < 1)?_c('ProductNotFound'):_vm._e(),(_vm.product.totalProduct !== 0)?_c('b-col',{attrs:{"md":"8 mt-4"}},[_c('MainPagination',{attrs:{"dataPagination":{
            perPage: _vm.product.perPage,
            currentPage: _vm.product.currentPage,
            totalProduct: _vm.product.totalProduct,
            pagination: _vm.product.pagination,
            linkPagination: _vm.product.linkPagination
          }},on:{"clicked":_vm.updatePage}})],1):_vm._e()],1):_vm._e()],1)],1),_c('b-tab',{attrs:{"title":"Ürün Gir"}},[_c('b-card-text',[_c('AddCart',{attrs:{"detailProduct":_vm.product}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }