<template>
  <div class="d-flex flex-column d-flex justify-content-center height-not-found w-100">
    <div class="text-center my-5">
      <h1 class="text-danger font-weight-bold">Ürün Bulunamadı</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductNotFound'
}
</script>

<style scoped>
</style>
