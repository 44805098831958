<template>
<div class="row">
  <div class="fade show mt-2">
          <h5>Lütfen satışa başlamak için  kasayı açınız !!!</h5>
          <router-link to="terminal" class="btn btn-success" tag="button">Kasa İşlemleri</router-link>
          </div>
  </div>
</template>
<script>
export default {
  name: 'MoneyCaseStatus'
}
</script>
<style scoped>
</style>
